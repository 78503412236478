import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button",
      "style": {
        "position": "relative"
      }
    }}>{`Button`}</h1>
    <p>{`Buttons express what action will occur when the user clicks it. Buttons are used to initialize an action, and some examples include actions like Add, Save, and Delete.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Do not use Buttons as navigational elements. Instead, use `}<strong parentName="li">{`Links`}</strong>{` because it takes the user to a new page and is not associated with an action.`}</li>
      <li parentName="ul">{`Button labels tell users what will happen when they click the button. Use verbs that describe the action, such as `}<strong parentName="li">{`Add`}</strong>{` or `}<strong parentName="li">{`Delete`}</strong>{`. Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized) and no more than three words for button labels when possible.`}</li>
      <li parentName="ul">{`For Sets of Buttons, use specific labels, such as `}<strong parentName="li">{`Save`}</strong>{` or `}<strong parentName="li">{`Discard`}</strong>{`, instead of using `}<strong parentName="li">{`OK`}</strong>{`. This is particularly helpful when the user is confirming an action.`}</li>
    </ul>
    <h2 {...{
      "id": "button-types",
      "style": {
        "position": "relative"
      }
    }}>{`Button types`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={190}>Default</th>
      <td>When an action requires primary dominance on the page.</td>
    </tr>
    <tr>
      <th>Ghost button</th>
      <td>When an action does not require primary dominance on the page.</td>
    </tr>
    <tr>
      <th>Button with icon</th>
      <td>
        When words are not enough, icons can be used in buttons to better communicate what the button does. Icons are
        always paired with text.
      </td>
    </tr>
    <tr>
      <th>Icon button</th>
      <td>Usually used associated with a dropdown menu, settings panel.</td>
    </tr>
    <tr>
      <th>Disabled button</th>
      <td>Use when the user cannot proceed until an input is collected.</td>
    </tr>
    <tr>
      <th>Danger button</th>
      <td>When an action has potentially destructive effects on the user's data (delete, remove, etc).</td>
    </tr>
    <tr>
      <th>Set of buttons</th>
      <td>
        When an action required by the user has more than one option, always use a negative action button (secondary)
        paired with a positive action button (primary) in that order. Negative action buttons will be on the left.
        Positive action buttons should be on the right.
      </td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "button-sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Button sizes`}</h2>
    <Demo src='pages/components/button/ButtonSizes' mdxType="Demo" />
    <h2 {...{
      "id": "button-kinds--skins",
      "style": {
        "position": "relative"
      }
    }}>{`Button Kinds / Skins`}</h2>
    <Demo src='pages/components/button/ButtonSkins' mdxType="Demo" />
    <h2 {...{
      "id": "button-with-icons",
      "style": {
        "position": "relative"
      }
    }}>{`Button with icons`}</h2>
    <Demo src='pages/components/button/ButtonIcons' mdxType="Demo" />
    <h2 {...{
      "id": "loading-indicator",
      "style": {
        "position": "relative"
      }
    }}>{`Loading indicator`}</h2>
    <p>{`The loading indicator can be manually toggled on/off with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`loading`}</code>{` prop or automatically by returning a promise to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{` prop.`}</p>
    <Demo src='pages/components/button/ButtonLoading' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      